.card {
    .card-title {
        font-weight: $font-weight-normal;
    }
}

.card-icon {
    display: inline-flex;
    align-items: center;
    width: 64px;
    height: 64px;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 28px;
}

.count-card {
    width: 291px;
    margin-left: 88px;
    border-radius: 12px;

    @media screen and (max-width: 576px) {
        width: 290px;
        margin-left: 0;
        margin-top: 30px;
    }
    .card-img {
        border-radius: 12px;
        height: 100%;
        object-fit: cover;
    }
    .card-img-overlay {
        left: -30%;
        padding-top: 2.25rem;
        @media screen and (max-width: 576px) {
            left: 0;
        }
    }
    .count-box {
        display: inline-flex;
        width: 130px;
        height: 130px;
        margin: 9px;
        border-radius: 4px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 991px) {
            width: 110px;
            height: 110px;
        }

        @media screen and (max-width: 576px) {
            width: 100px;
            height: 100px;
        }
    }
}

.team-card {
    @media screen and (min-width: 992px) {
        max-width: 267px;   
    }
    .designation {
        color: #0f64ff;
        margin-bottom: 11px;
    }

    .social-links {
        a {
            color: $black;
            font-size: 1rem;
            margin-right: 6px;

            &.icon-fb:hover {
                color: $primary;
            }
            &.icon-twitter:hover {
                color: $info;
            }
            &.icon-insta:hover {
                color: $danger;
            }
        }
    }
}

.experience-card {
    display: flex;
    margin-top: 22px;
    margin-bottom: 22px;

    .experiance-details {
        display: flex;
        flex-direction: column;
        padding-left: 27px;
    }
}

.grid-margin {
    margin-bottom: 20px;
}

.card-group {
    .card{
        transition: background-color 0.3s ease-in-out;
        &:hover {
            background-color: #e5e5e5;
        }
    }
}