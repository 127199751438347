.portfolio-row {
    margin-bottom: 10px;
}
.portfolio-item {
    display: inline-block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin-right: 9px;

    &:last-child {
        margin-right: 0;
    }

    img {
        width: 100%;
    }
}