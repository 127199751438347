.carousel-caption {
top: 50%;
transform: translateY(-50%);

    * {
        color: $white;
    }

    p {
        font-size: 16px;
        font-weight: $font-weight-medium;
    }
}

.carousel-indicators li {
    height: 4px;
    width: 4px;
    border-radius: 50%;
}

.carousel-control-prev, .carousel-control-next {
    top: 50%;
    bottom: auto;
    width: 30px;
    height: 30px;
    background-color: $white;
    border: 2px solid theme-color(primary);
    color: theme-color(primary);
    border-radius: 50%;
    opacity: 1;
    font-size: 12px;

    &:hover, &:focus, &:active {
        background-color: theme-color(primary);
    }
}

.carousel-control-prev {
    transform: translate(-50%, -50%);
}

.carousel-control-next {
    transform: translate(50%, -50%);
}