h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
    color: $headings-color;
}
h1,.h1 {
    font-size: $h1-font-size;
    line-height: 1.5;
}
h2,.h2 {
    font-size: $h2-font-size;
    line-height: 1.5;
}
h3,.h3 {
    font-size: $h3-font-size;
    line-height: 1.5;
}
h4,.h4 {
    font-size: $h4-font-size;
    line-height: 1.5;
}
h5,.h5 {
    font-size: $h5-font-size;
    line-height: 1.5;
}
h6,.h6 {
    font-size: $h6-font-size;
    line-height: 1.5;
}
.display-1, .display-2, .display-3, .display-4 {
    color: $headings-color;
    font-weight: $font-weight-light;
}

.display-1 {
    font-size: $display1-size;
    line-height: 1.2;
}
.display-2 {
    font-size: $display2-size;
    line-height: 1.2;
}
.display-3 {
    font-size: $display3-size;
    line-height: 1.2;
}
.display-4 {
    font-size: $display4-size;
    line-height: 1.2;
}

p{
    font-weight: $font-weight-regular;
    line-height: 27px;
}

blockquote, .blockquote{
    font-size: 1rem;
    font-weight: $font-weight-light;

    p {
        margin-bottom: 0.5rem;
        line-height: 1.63;
    }

    footer, .blockquote-footer {
        font-size: 0.8125rem;
        color: #000000;
        font-weight: $font-weight-medium;
    }
}

.font-weight-medium {
    font-weight: $font-weight-medium;
}

.font-weight-semibold {
    font-weight: $font-weight-semibold;
}

.lead {
    font-size: 1rem;
}