.alert{
    color: $white;
    font-size: 12px;
    line-height: 1.33;
    border: 0;

    .close {
        font-size: 12px;
        color: $white;
        opacity: 1;
        line-height: 1.33;
    }
}