/*!
    Miri UI Kit Free V1.0.0
    Developed by BootstrapDash(https://www.bootstrapdash.com/)
*/

@import "miri-ui-kit/variables";
@import "miri-ui-kit/font";

// Bootstrap styles

@import "../../node_modules/bootstrap/scss/bootstrap";

// Basic Styles

@import "miri-ui-kit/typography";
@import "miri-ui-kit/utilities";

//Mixins

@import "miri-ui-kit/mixins/range";
@import "miri-ui-kit/mixins/card";
@import "miri-ui-kit/mixins/buttons";

// Components

@import "miri-ui-kit/components/alert";
@import "miri-ui-kit/components/badges";
@import "miri-ui-kit/components/buttons";
@import "miri-ui-kit/components/card";
@import "miri-ui-kit/components/carousel";
@import "miri-ui-kit/components/dropdown";
@import "miri-ui-kit/components/footer";
@import "miri-ui-kit/components/forms";
@import "miri-ui-kit/components/header";
@import "miri-ui-kit/components/lightbox";
@import "miri-ui-kit/components/lists";
@import "miri-ui-kit/components/login";
@import "miri-ui-kit/components/navbar";
@import "miri-ui-kit/components/pagination";
@import "miri-ui-kit/components/profile";
@import "miri-ui-kit/components/portfolio";
@import "miri-ui-kit/components/progress";
@import "miri-ui-kit/components/range";
@import "miri-ui-kit/components/tabs";


