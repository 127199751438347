@mixin track(){
    width: $track-width;
    height: $track-height;
    cursor: pointer;
    animate: 0.2s;
  }
  
  @mixin thumb(){
    height: $thumb-height;
    width: $thumb-width;
    border-radius: $thumb-radius;
    background: $thumb-color;
    cursor: pointer;
  }

input[type=range] {
    -webkit-appearance: none;
    margin: $thumb-height/2 0;
    width: $track-width;
  
    &:focus {
      outline: none;
    }
  
    &::-webkit-slider-runnable-track {
      @include track();
      background: $track-color;
      border-radius: $track-radius;
    }
    
    &::-webkit-slider-thumb {
      @include thumb();
      -webkit-appearance: none;
      margin-top: (($track-height - $thumb-height) / 2);
    }
  
    &:focus::-webkit-slider-runnable-track {
      background: $track-color;
    }
  
    &::-moz-range-track {
      @include track();
      background: $track-color;
      border-radius: $track-radius;
    }
    &::-moz-range-thumb {
       @include thumb();
    }
  
    &::-ms-track {
      @include track(); 
      background: transparent;
      border-color: transparent;
      border-width: $thumb-width 0;
      color: transparent;
    }
  
    &::-ms-fill-lower {
      background: $track-color;
      border-radius: $track-radius*2;
    }
    &::-ms-fill-upper {
      background: $track-color;
      border-radius: $track-radius*2;
    }
    &::-ms-thumb {
      @include thumb();
    }
    &:focus::-ms-fill-lower {
      background: $track-color;
    }
    &:focus::-ms-fill-upper {
      background: $track-color;
    }
  }