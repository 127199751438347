.contact-list {
    list-style: none;
    padding: 10px 0;
    .contact-list-item {
        padding: 5px 0;
        display: flex;
        width: 100%;
        align-items: center;

        .profile-pic {
            display: flex;
            width: 30px;
            height: 30px;
            position: relative;
            padding: 0;
            align-items: center;

            img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }

            &::before {
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 2px solid $white;
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: $white;
            }

            &.online::before{
                background-color: $success;
            }
            &.away::before{
                background-color: $warning;
            }
            &.busy::before{
                background-color: $danger;
            }

            &.offline::before{
                background-color: $light;
            }
        }
        .contact-details {
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            .contact-name {
                font-weight: $font-weight-medium;
                color: $black;
                margin-bottom: 0;
            }
            .contact-designation{
                font-size: 12px;
                margin-bottom: 0;
            }
        }
    }
}

.comment-list-item {
    padding-bottom: 40px;
    padding-top: 40px;
    border-bottom: 1px solid #e7eaf3;

    &:last-child {
        border-bottom: none;
    }
}

.comment-list-item-header {
    display: flex;
    align-items: center;

    .pro-pic {
        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }
    .user-details {
        padding-left: 12px;
        margin-right: auto;

        .user-name {
            margin-bottom: 0;
        }
        .date-published {
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    i{
        font-size: 15px;
    }
}