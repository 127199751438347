.img-raised {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.27);
}

.img-rounded {
    border-radius: 50rem;
}

.text-black {
    color: $black;
}

.raise-on-hover{
    transition: transform 0.3s ease-in-out;
    &:hover {
        transform: scale(1.05)translateY(-10px);
    }
}