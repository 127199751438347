.Profile-header {
    border-bottom: 1px solid #e7eaf3;
    padding-top: 47px;
    padding-bottom: 58px;
    margin-bottom: 90px;
}

.profile-intro {
    @media screen and (min-width:768px) {
        max-width: 629px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
    }
}