@mixin button-solid-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
    color: $color;
    border-color: $color;
    background-color: $white;

    @include hover {
        color: $color-hover;
        background-color: $active-background;
        border-color: $active-border;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show>&.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        &:focus {

            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow !=none {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
            }

            @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
            }
        }
    }
}

@mixin button-soft-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
    color: $color;
    border-color: $color;
    background-color: rgba($color, 0.1);
    border-color: transparent;

    @include hover {
        color: $color-hover;
        background-color: $active-background;
        border-color: $active-border;
        box-shadow: 0 4px 11px rgba($color, 0.35);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show>&.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        &:focus {

            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow !=none {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
            }

            @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
            }
        }
    }
}